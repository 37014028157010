import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {Link as RouterLink} from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import {useGlobalState} from '../../../state';
import Tooltip from '@material-ui/core/Tooltip';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor:theme.palette.navBackground.default,
        width: '100%',
        zIndex:50,
        position: 'relative',
        boxShadow:"0 0.75rem 1.5rem rgba(18,38,63,.03)",
        ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
            position: 'fixed'
          }
    },
    desktopNav: {
        backgroundColor:theme.palette.navBackground.default,
        width: '100%',
        zIndex:50,
        position: 'relative',
        boxShadow:"0 0.75rem 1.5rem rgba(18,38,63,.03)",
        ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
            position: 'fixed'
          }
    },
    farmDash:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
    },
    farmIcon:{
        display:"flex",
       marginRight:"30px",
       cursor:"pointer",
       color:"rgb(102, 102, 102)",
       stroke:"white",
       strokeWidth:0.6,
    },
    farmIconbg:{
        color:theme.palette.primary,
        display:"flex",
        marginRight:"20px",
        cursor:"pointer",
        stroke:theme.palette.navBackground.default,
        strokeWidth:0.6,
    },
    farmIconMob:{
        color:theme.palette.primary,
        display:"flex",
        cursor:"pointer",
        stroke:theme.palette.navBackground.default,
        strokeWidth:0.6,
    },
    flexGrow: {
        flexGrow: 1
    },
    sideBarButton: {
        color:theme.palette.primary.contrastText
    },
    signOutButton: {
        marginLeft: theme.spacing(1),
        color:theme.palette.white
    },
    logo:{
        height:50,
    },
    iconColor:{
        color: theme.palette.navActive
    }
}));

const Topbar = props => {
    const {className, onSidebarOpen,isDesktop,match, ...rest} = props;

    const classes = useStyles();

    
    // eslint-disable-next-line
    const [{user}, dispatch] = useGlobalState();
    const history = useHistory();
    const farmID = match.params['farmID'];


    const dailyEntry = () => {
        history.push(`/farm/${farmID}/daily-entry`)
    }

    const handlePageChange = () => {
        history.push({
            pathname: `/farm/${farmID}/dashboard`,
            search: `?notification=`+true
          })
          
    }
    return (
        <AppBar
            {...rest}
            className={!isDesktop  ? clsx(classes.root, className) : clsx(classes.desktopNav, className)}
        >
            <Toolbar>
            <Hidden lgUp>
            <RouterLink to="/">
                    <img
                        className={classes.logo}
                        alt="Logo"
                        src="/images/logos/poultry-client-mobile-view.png"
                    />
                </RouterLink>
                </Hidden>
                <div className={classes.flexGrow}/>
                <Tooltip title="LogOut" style={{display:"none"}}>
                    <IconButton
                        className={classes.signOutButton}
                        color="primary"
                        onClick={
                            () => dispatch({type: 'logout'})
                        }
                    >
                        <InputIcon/>
                    </IconButton>
                </Tooltip>
                    <div className={classes.farmDash}>
                        <div  className={ isDesktop ? classes.farmIcon : classes.farmIconbg }>
                          
                          <Tooltip
                          arrow
                          disableFocusListener
                          title={<span style={{fontSize:"14px", lineHeight:"1rem",}}>Daily Entry</span>}
                          >
                               <CalendarTodayOutlinedIcon className={classes.iconColor} onClick={dailyEntry}  fontSize="small" />
                          </Tooltip>
                       
                      </div>
                      {
                        (user?.role !== "SUPER" && user?.role !== "DRIVER") &&
                        <div className={ isDesktop ? classes.farmIcon : classes.farmIconbg }>

                            <Tooltip
                                arrow
                                disableFocusListener
                                title={<span style={{fontSize:"14px", lineHeight:"1rem",}}>Reminder Notification</span>}
                                >
                                        <NotificationsOutlinedIcon className={classes.iconColor} strokeWidth="0.8"  onClick={handlePageChange} fontSize="medium" />
                            </Tooltip>
                         
                      </div>
                      }
                            <Hidden lgUp>
                                <div className={ isDesktop ? classes.farmIcon : classes.farmIconMob }>
                                    <Tooltip
                                        arrow
                                        disableFocusListener
                                        title={<span style={{fontSize:"14px", lineHeight:"1rem",}}>Refresh</span>}
                                        className={ classes.farmIconbg }
                                        >
                                            <RefreshIcon className={classes.iconColor} strokeWidth="0.8"  
                                            onClick={() => window.location.reload()} fontSize="medium" />
                                    </Tooltip>
                                </div>
                            </Hidden>
                    </div> 
                <Hidden lgUp>
                    <IconButton
                        color="primary"
                        onClick={onSidebarOpen}
                        className={classes.sideBarButton}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Hidden>

            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func,
    isDesktop: PropTypes.bool,
};

export default Topbar;