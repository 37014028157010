import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import {useGlobalState} from '../../../../state';
import {Link as RouterLink} from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        backgroundColor: theme.palette.navBackground.default,
        width: '100%',
        zIndex:50,
        position: 'relative',
        ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
            position: 'relative'
          }
    },
    
    flexGrow: {
        flexGrow: 1
    },
    logo:{
        height:50
    },
    sideBarButton: {
        color:theme.palette.primary.contrastText
    },
    signOutButton: {
        marginLeft: theme.spacing(1),
        color:theme.palette.white
    }
}));

const Topbar = props => {
    const {className, onSidebarOpen, ...rest} = props;

    const classes = useStyles();

    // eslint-disable-next-line
    const [, dispatch] = useGlobalState();

    return (
        <AppBar
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Toolbar>
            <Hidden lgUp>
            <RouterLink to="/">
                    <img
                        className={classes.logo}
                        alt="Logo"
                        src="/images/logos/poultry-client-mobile-view.png"
                    />
                </RouterLink>
                </Hidden>
                <div className={classes.flexGrow}/>
                <Tooltip title="LogOut" style={{display:"none"}}>
                    <IconButton
                        className={classes.signOutButton}
                        color="primary"
                        onClick={
                            () => dispatch({type: 'logout'})
                        }
                    >
                        <InputIcon/>
                    </IconButton>
                    </Tooltip>
                <Hidden lgUp>
                     <IconButton
                        color="primary"
                        onClick={() => window.location.reload()}
                        className={classes.sideBarButton}>
                        <RefreshIcon/>
                    </IconButton>
                    <IconButton
                        color="primary"
                        onClick={onSidebarOpen}
                        className={classes.sideBarButton}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func
};

export default Topbar;