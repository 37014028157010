import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import moment from "moment"
const useCardStyles = makeStyles({
    card: {
        minWidth: 275,
    },
    cardActionsContainer: {
        display: 'flex',
        flexDirection: 'column'
    }
});

const Mobile = ({data, onDelete, onEdit}) => {
    const classes = useCardStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setAnchorEl(null);
        onDelete();
    };

    const handleEdit = () => {
        setAnchorEl(null);
        onEdit();


    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    return <Grid item>
        <Card className={classes.card}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={handleClick}>
                        <MoreVertIcon/>
                    </IconButton>
                }
                title={`No : ${data.id}`}
            />
            <CardContent>
                <div style={{display:"flex"}}>
                    
                    <div  style={{width:"50%"}}>
                        <Typography variant="h5" component="h2">
                             Date
                        </Typography>   
                        <Typography variant="h5" component="h2">
                             Material Name
                        </Typography>   
                        <Typography variant="h5" component="h2">
                            Quantity
                        </Typography>
                        <Typography variant="h5" component="h2">
                            Cost Per Unit
                        </Typography>
                        <Typography variant="h5" component="h2">
                            Cost
                        </Typography>
                    </div>
                    <div style={{width:"50%"}}>
                      <Typography color="textSecondary">
                        {moment(data.date).format("DD-MM-YYYY")}
                        </Typography>
                        <Typography color="textSecondary">
                            {data.rawMaterialName}
                        </Typography>
                        <Typography color="textSecondary">
                            {data.quantity}
                        </Typography>
                        <Typography color="textSecondary">
                            {data.costperunit}
                        </Typography>
                        <Typography color="textSecondary">
                            {data.cost}
                        </Typography>
                    </div>
                </div>
            </CardContent>
        </Card>
        <Menu
            id="medicine-stock-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
    </Grid>
};

export default Mobile;