import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from "../../../common/formValidator";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import red from "@material-ui/core/colors/red";

const schema = {
  quantity: {
    presence: { allowEmpty: false, message: " is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
    },
  },
  costperunit: {
    presence: { allowEmpty: false, message: " is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
    },
  },
  date:{
    presence: { allowEmpty: false, message: " is required" },
  },
  rawMaterialIdx: {
    presence: { allowEmpty: false, message: " is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.text.default,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  progressBar: {
    margin: theme.spacing(2),
    position: "absolute",
    top: "40%",
    zIndex: "9999",
  },
  typo: {
    marginLeft: theme.spacing(0.25),
    marginTop: theme.spacing(-1),
    fontSize: "initial",
  },
  typoRed: {
    marginLeft: theme.spacing(0.25),
    marginTop: theme.spacing(-1),
    fontSize: "initial",
    color: red[900],
  },
}));

const RawMaterialStorageStock = ({
  deleteErrorMessage,
  errorMessage,
  onEdit,
  data,
  onSave,
  handleClose,
  showDialog,
  editMode,
  match,
  rawMaterials,
  availableStorage,
  getAvailableRawMaterialStorageByID
}) => {
  const classes = useStyles();
  const [formState, hasError, handleChange, updateData] =
    useFromValidator(schema);
  const [popupLoading, setPopupLoading] = useState(false);
  const [availableStockErr, setaavailableStockErr] = useState(null);
  const [ShowQuantityMessage, setShowQuantityMessage] = useState(null);
  const [showNotification, setshowNotification] = useState(null);

  const resetBeforeEdit = () => {
    setaavailableStockErr(null);
    if (editMode) {
      setShowQuantityMessage(
      `The quantity should be less than or equal to ${availableStorage}`
        );
    }
    if (showDialog) {
      setShowQuantityMessage(null);
      setshowNotification(null);
      setPopupLoading(false);
      updateData(data, editMode);
    }
  };
  useEffect(resetBeforeEdit, [showDialog]);
  const handleFormulaChange = (event) => {
    let materialID = rawMaterials[event.target.value]?.id
    let entryDate = formState.values.date || new Date()
    let storageID
    if(editMode) {
      storageID = data?.id
    }else {
      storageID = 0
    }
    if( materialID > 0) (
      getAvailableRawMaterialStorageByID(materialID,entryDate,storageID)
    )
    handleChange(event)
  }


  const handleQuantityChange = event => {
    if(event.target.value > availableStorage) {
      setShowQuantityMessage(
        `The quantity should be less than or equal to ${availableStorage}`
    );
    }
    handleChange(event)
  }

  const onSubmit = () => {
    setPopupLoading(true);
    const tempData = {
      ...formState.values,
      rawMaterialId:rawMaterials[formState.values.rawMaterialIdx].id,
      rawMaterialName:rawMaterials[formState.values.rawMaterialIdx].name
    };
    //available stock validation
    if (tempData.quantity > availableStorage) {
      setPopupLoading(false);
      return
    }
    var errorElement = document.getElementById("error");
    if (errorElement) {
      errorElement.scrollIntoView();
    }
    if (editMode) {
      onEdit(tempData, setPopupLoading);
    } else onSave(tempData, setPopupLoading);
  };




  function deleteNotification() {
    setshowNotification(null);
  }

  useEffect(() => {
    if (
      availableStorage > 0 &&
      (formState.values.quantity < 0 ||
        formState.values.quantity > availableStorage)
    ) {
      setshowNotification(true);
    } else {
      setshowNotification(null);
    }
    if (availableStorage === 0 && showNotification === true) {
      setshowNotification(null);
    }
    setShowQuantityMessage(
      `The quantity should be less than or equal to ${availableStorage}`
  );
    // eslint-disable-next-line
  }, [formState.values.quantity, availableStorage]);

  return (
    <Dialog
      disableBackdropClick
      open={showDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog- title">
        {editMode ? "Update" : "New"}  Raw material Storage
      </DialogTitle>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>

          <div>
             {
               popupLoading ? 
                <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/ >
                </div> : ""
             }
            <div id="error">
              {errorMessage ? (
                <Chip
                  onDelete={deleteErrorMessage}
                  color="primary"
                  label={errorMessage}
                />
              ) : (
                ""
              )}
            </div>
            <br />
            <InputLabel
              id="type-select-outlined-label"
              className={
                formState.values.quantity > availableStorage
                  ? classes.typoRed
                  : classes.typo
              }
            >
              {showNotification === true ? (
                <Chip
                  color="primary"
                  size="small"
                  onDelete={deleteNotification}
                  label={ShowQuantityMessage}
                  className={classes.chip}
                />
              ) : (
                ""
              )}
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                className={classes.textField}
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Date"
                format="dd/MM/yyyy"
                value={formState.values.date || null}
                onChange={(data) => {
                  handleChange(
                    { target: { name: "date", value: data } });
                }}
                animateYearScrolling
                name="date"
                style={{ backgroundColor: "#ffffff" }}
              />
            </MuiPickersUtilsProvider>

            <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">
               Material
              </InputLabel>
              <Select
                id="materialName"
                value={
                  formState.values.rawMaterialIdx !== undefined
                    ? formState.values.rawMaterialIdx
                    : ""
                }
                onChange={(e) => {
                  handleChange(e, handleFormulaChange(e));
                }}
                name="rawMaterialIdx"
              >
                  {rawMaterials !== undefined && rawMaterials !== null ?
                            rawMaterials.map((rawMaterial, index) => {
                                    return <MenuItem key={rawMaterial.id} value={index}>{rawMaterial.name}</MenuItem>
                                }
                            ) : []}
              </Select>
            </FormControl>


            <InputLabel id="type-select-outlined-label">
              {formState.values !== undefined &&
              formState.values !== null &&
              formState.values.rawMaterialIdx !== undefined &&
              formState.values.rawMaterialIdx !== null &&
              formState.values.rawMaterialIdx !== "" &&
              availableStorage !== null
                ? `Available Stock: ${availableStorage}`
                : availableStockErr}{" "}
            </InputLabel>

            <TextField
              className={classes.textField}
              error={hasError("quantity")}
              fullWidth
              helperText={
                hasError("quantity") ? formState.errors.quantity[0] : null
              }
              label="Quantity"
              name="quantity"
              onChange={(event) => handleQuantityChange(event)}
              type="number"
              value={formState.values.quantity || ""}
              variant="outlined"
              style={{ backgroundColor: "#ffffff" }}
            />

            <TextField
              className={classes.textField}
              error={hasError("costperunit")}
              fullWidth
              helperText={
                hasError("costperunit") ? formState.errors.costperunit[0] : null
              }
              label="Cost per unit"
              name="costperunit"
              onChange={(event) => handleChange(event)}
              type="number"
              value={formState.values.costperunit || ""}
              variant="outlined"
              style={{ backgroundColor: "#ffffff" }}
            />

            <DialogActions>
              <Button
                className={classes.signInButton}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                disabled={!formState.isValid}
                onClick={onSubmit}
              >
                Save
              </Button>
            </DialogActions>
          </div>

      </DialogContent>
    </Dialog>
  );
};

export default RawMaterialStorageStock;
