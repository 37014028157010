import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from "../../../common/formValidator";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import NonWeightTypeMaterial from "./materialTypeComponents/nonWeightTypeMaterial";
import WeightTypeMaterial from "./materialTypeComponents/weightTypeMaterial";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import parentMaterialSchema from '../../../formSchema/parentMaterial';
import Chip from '@material-ui/core/Chip';

import {useGlobalState} from '../../../state';
import { version } from '../../../common/enums';
import { MaterialFunc } from '../../../common/material';
/**
 * metrics: kgs(2), unit(3), trays(1), litres(4)
 *
 * inventory type: Feed Mill (1) & Farm (2)
 *
 * Farm => farm, shed dropdown, storage check box
 * Feed Mill => doesn't need any other additional html elements
 *
 * Note: cost per unit && GST is required for all types of metrics, GST -> optional
 * cost per unit -> required
 *
 * kgs => display weight type drop down, text fields includes (Bill weight, delivery weight)
 * litres & units & trays => display quantity
 *
 * based on whether the inventory type, certain html elements will be displayed
 */


const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.text.default,
  },
  formControl: {
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    minWidth: 160,
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  datePicker: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
    minWidth: 160,
  },
  dialogWidth: {
    minWidth: "1042px",
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  progressBar: {
    margin: theme.spacing(2),
    position: "absolute",
    top: "40%",
    zIndex: "9999",
  },
}));

function ParentMaterial({
  deleteErrorMessage,
  errorMessage,
  materialTypes,
  materialNames,
  farms,
  sheds,
  onGetShed,
  onGetMaterialNames,
  onEdit,
  data,
  onSave,
  handleClose,
  showDialog,
  editMode = false,
  materialIndex,
  storageOption,
  setMaterialNames,
  gstTaxProfiles,
  render=false,
  isCommissionPurchase
}) {
  const [{user}] = useGlobalState();
  const [disabled, setDisabled] = useState(true);
  const classes = useStyles();
  //All schema
  const {schema,weightTypeSchema,shedSchema,farmSchema,nonweightTypeSchema } = parentMaterialSchema(user.versionID === version.pro);
  // eslint-disable-next-line
  const [formState, hasError, handleChange, updateData, setFormState] =
  useFromValidator(schema);
  const history = useHistory();
  const [showMaterialNames, setShowMaterialNames] = useState(false);
  const [showWeightType, setShowWeightType] = useState(false);
  const [showNonWeightType, setShowNonWeightType] = useState(false);
  const [materialData, setMaterialData] = useState({});
  const [inventoryTypeFarm, setInventoryTypeFarm] = useState(false);
  const [reset, setreset] = useState(true);

  const handleChangeMaterialType = async (event) => {
    await onGetMaterialNames(materialTypes[event.target.value].id);
    setShowMaterialNames(true);
    /**
     * materialIndex form state set to "" upon "Material type" change.
     */
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        materialTypeIndex: event.target.value,
        materialIndex: "",
      },
    });
    setMaterialData({});
    if (!disabled) {
      setDisabled(true);
    }
    setShowWeightType(false);
    setShowNonWeightType(false);

    setreset((reset) => !reset);
  };

  const handleMaterialChange = (event) => {
    setShowWeightType(
      materialNames !== undefined &&
        materialNames !== null &&
        materialNames[event.target.value] !== undefined &&
        materialNames[event.target.value] !== null &&
        materialNames[event.target.value].unitID === 2
        ? true
        : false
    );
    setShowNonWeightType(
      materialNames !== undefined &&
        materialNames !== null &&
        materialNames[event.target.value] !== undefined &&
        materialNames[event.target.value] !== null &&
        (materialNames[event.target.value].unitID === 1 ||
          materialNames[event.target.value].unitID === 3 ||
          materialNames[event.target.value].unitID === 4)
        ? true
        : false
    );
    setInventoryTypeFarm(
      materialNames !== undefined &&
        materialNames !== null &&
        materialNames[event.target.value] !== undefined &&
        materialNames[event.target.value] !== null &&
        materialNames[event.target.value].inventoryType === 2
        ? true
        : false
    );
    setreset((reset) => !reset);
    prepareSchemaObjectForChildComponent(materialNames[event.target.value]);
    setMaterialData({});
    if (!disabled) {
      setDisabled(true);
    }
  };

  const resetBeforeEdit = () => {
    if (showDialog) {

      updateData(data, editMode);
      if (editMode) {
        /**
         * For weight type material - kgs
         * if storage = 0 display shed dropdown
         * with selected value and storage checkbox
         * unchecked and enabled - edit mode.
         */
        if(user.versionID !== version.pro){
          data.storage = Boolean(data.storage);
        }
        setShowWeightType(
          data !== undefined && data !== null && data.unitID === 2
            ? true
            : false
        );
        setShowNonWeightType(
          data !== undefined &&
            data !== null &&
            (data.unitID === 1 || data.unitID === 3 || data.unitID === 4)
            ? true
            : false
        );
        setInventoryTypeFarm(
          data !== undefined && data !== null && data.inventoryType === 2
            ? true
            : false
        );
        setShowMaterialNames(true);
        prepareSchemaObjectForChildComponent(data);
        setMaterialData(data);
        setreset(!reset);  // to update the weightTypeMaterial, Don't use true or false directly because if same value found in const `reset` update won't occur  
      }
    }
  };

  useEffect(resetBeforeEdit, [render]);

  const prepareSchemaObjectForChildComponent = (data) => {
    //weight type - kgs - 2
    /***
     * Below condition executed to prepare schema object for validation, only in case
     * of weight type kg and if the the inventory type is farm, for the sake of including
     * shed/farm dropdown validations
     */
    if (data.unitID === 2 && data.inventoryType === 2) {
      Object.assign(weightTypeSchema, farmSchema);
      Object.assign(weightTypeSchema, data.storage === true ? {} : shedSchema);
    }
    //weight type - litres (4), units(3), trays(1)
    /***
     * Below condition executed to prepare schema object for validation, only in case
     * of weight types litres, units, trays and if the the inventory type is farm, for the sake of including
     * shed/farm dropdown validations
     */
    else if (
      (data.unitID === 1 || data.unitID === 3 || data.unitID === 4) &&
      data.inventoryType === 2
    ) {
      Object.assign(nonweightTypeSchema, farmSchema);
      Object.assign(
        nonweightTypeSchema,
        data.storage === true ? {} : shedSchema
      );
    }
    //For Feed mill below condition is executed, where farm and shed is not needed
    else {
      delete weightTypeSchema.farmIndex;
      delete weightTypeSchema.shedIndex;
      delete nonweightTypeSchema.farmIndex;
      delete nonweightTypeSchema.shedIndex;
    }
  };
  const onSubmit = () => {
    let billValue = 0;

    let taxValue = 1; //If there is no Tax then multiply by 1
    let materialcommissionPerUnit = 0
    // If the Tax % is greater than 0, then calculate tax in decimals

    if(!MaterialFunc.isValidMaterial(materialTypes,formState.values.materialTypeIndex)){
      return false;
    }
    if (materialData?.commissionPerUnit > 0) {
      materialcommissionPerUnit = materialData.commissionPerUnit
    }
    if(user.versionID === version.pro){
      billValue =  materialData.deliveryWeight * (materialData.costPerUnit + materialcommissionPerUnit) * taxValue;
      let formData;
      if(editMode){
        formData = {
          ...data,
          date : formState.values.date,
          purchaseMaterials : []
        };
      }
      else{
        formData = {
          date : formState.values.date,
          purchaseMaterials : []
        };
      }

      var pObj = {
        ...formState.values,
        ...materialData,
        materialTypeId: materialTypes[formState.values.materialTypeIndex].id,
        materialId: materialNames[formState.values.materialIndex].id,
        materialName: materialNames[formState.values.materialIndex].materialName,
        billValue: billValue,
        inventoryType:materialNames[formState.values.materialIndex].inventoryType
      };

      if(farms[materialData.farmIndex] && farms[materialData.farmIndex]["name"]){
         pObj["farmName"] = {String: farms[materialData.farmIndex]["name"], Valid: true};
      } 
      if(sheds[materialData.shedIndex] && sheds[materialData.shedIndex]["name"]){
        pObj["shedName"] = {String: sheds[materialData.shedIndex]["name"], Valid: true};
      }

      formData.purchaseMaterials.push(pObj);

      if(editMode){
        formData['id'] = data.purchaseID;
        formData.purchaseMaterials[0].id = data.mID; //Only one material per array in pro version = temp material id
      }
      formData['totalBillValue'] = formData.purchaseMaterials[0].billValue;
      onSave(formData);
    }
    else{
      if (materialData.tax < 0) {
        taxValue = 1 + materialData.tax / 100;
      }
      if (!materialData.weightType) {
        billValue = materialData.quantity * (materialData.costPerUnit + materialcommissionPerUnit ) * taxValue;
      } else if (materialData.weightType === 1) {
        billValue = materialData.billWeight * (materialData.costPerUnit + materialcommissionPerUnit ) * taxValue;
      } else if (materialData.weightType === 2) {
        billValue =
          materialData.deliveryWeight * (materialData.costPerUnit + materialcommissionPerUnit ) * taxValue;
      }
      let obj = {
        ...formState.values,
        ...materialData,
        materialTypeId: materialTypes[formState.values.materialTypeIndex].id,
        materialId: materialNames[formState.values.materialIndex].id,
        materialName: materialNames[formState.values.materialIndex].materialName,
        taxProfileID:
          formState.values.gstTaxProfileIndex !== undefined &&
          formState.values.gstTaxProfileIndex !== null &&
          gstTaxProfiles[formState.values.gstTaxProfileIndex] !== undefined &&
          gstTaxProfiles[formState.values.gstTaxProfileIndex] !== null
            ? gstTaxProfiles[formState.values.gstTaxProfileIndex].id
            : null,
        gst:
          formState.values.gstTaxProfileIndex !== undefined &&
          formState.values.gstTaxProfileIndex !== null &&
          gstTaxProfiles[formState.values.gstTaxProfileIndex] !== undefined &&
          gstTaxProfiles[formState.values.gstTaxProfileIndex] !== null
            ? gstTaxProfiles[formState.values.gstTaxProfileIndex].taxPercentage
            : null,
        billValue: billValue,
        inventoryType:
          materialNames[formState.values.materialIndex].inventoryType
      };

      if(farms[materialData.farmIndex] && farms[materialData.farmIndex]["name"]){
        obj["farmName"] =  {String: farms[materialData.farmIndex]["name"], Valid: true};
      }
      if(sheds[materialData.shedIndex] && sheds[materialData.shedIndex]["name"]){
        obj["shedName"] = {String: sheds[materialData.shedIndex]["name"], Valid: true};
      }

      if (editMode) {
        onEdit(obj);
        return;
      }
      onSave(obj);
    }
  };

  return (
    <Dialog
      disableBackdropClick
      maxWidth="sm"
      open={showDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog- title">
        {editMode ? "Update" : "Add"} Purchase Material
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          <br />
          <br />
          {
              (user.versionID === version.pro) &&
              <>
              <div id="error">
                 {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
              </div> 
              <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    className={classes.textField}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                      handleChange({ target: { name: 'date', value: data } })
                    }}
                    animateYearScrolling
                    name="date"
                    style={{backgroundColor:"#ffffff",width:'70%'}}
                />
            </MuiPickersUtilsProvider>
            </FormControl>
            <br/>
            </>
            }
          <FormControl className={classes.formControl}>
            <InputLabel id="farm-select-outlined-label">
              Material Type
            </InputLabel>
            <Select
              id="materialTypeid-select"
              value={
                formState.values.materialTypeIndex !== undefined && formState.values.materialTypeIndex !== -1
                  ? formState.values.materialTypeIndex
                  : ""
              }
              onChange={handleChangeMaterialType}
              name="materialTypeIndex"
            >
              {materialTypes.map((materialType, index) => (
                <MenuItem key={materialType.id} value={index}>
                  {materialType.materialType}
                </MenuItem>
              ))}
            </Select>
            <Link
              color="inherit"
              href="#"
              onClick={() => {
                return history.push("/material_master");
              }}
              className={classes.link}
              style={{ color: "#02a6c7" }}
            >
              {" "}
              Add new Material
            </Link>
          </FormControl>
          {(showMaterialNames && materialNames !== undefined) && (
            <FormControl className={classes.formControl}>
              <InputLabel id="materialName-select-label">
                Material Name
              </InputLabel>
              <Select
                id="materialName-select"
                value={
                  formState.values.materialIndex !== undefined
                    ? formState.values.materialIndex
                    : ""
                }
                onChange={(event) => {
                  handleChange(event, handleMaterialChange);
                }}
                name="materialIndex"
              >
                {materialNames.map((material, index) => (
                  <MenuItem key={material.id} value={index}>
                    {material.materialName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {showWeightType === true && (
            <WeightTypeMaterial
              showWeightType={showWeightType}
              setDisabled={setDisabled}
              disabled={disabled}
              materialData={materialData}
              setMaterialData={setMaterialData}
              inventoryTypeFarm={inventoryTypeFarm}
              farms={farms}
              onGetShed={onGetShed}
              sheds={sheds}
              setShowWeightType={setShowWeightType}
              reset={reset}
              editMode={editMode}
              data={data}
              schema={weightTypeSchema}
              isCommissionPurchase={isCommissionPurchase}
              showRawMaterialstorage={materialTypes[formState.values.materialTypeIndex].id === 1 } //raw material storage
            />
          )}

          {showNonWeightType === true && (
            <NonWeightTypeMaterial
              showNonWeightType={showNonWeightType}
              setDisabled={setDisabled}
              materialData={materialData}
              setMaterialData={setMaterialData}
              inventoryTypeFarm={inventoryTypeFarm}
              farms={farms}
              onGetShed={onGetShed}
              sheds={sheds}
              setshowNonWeightType={setShowNonWeightType}
              reset={reset}
              editMode={editMode}
              data={data}
              schema={nonweightTypeSchema}
              materialNames={materialNames}
              materialIndex={formState.values.materialIndex}
              isCommissionPurchase={isCommissionPurchase}
              
            />
          )}
          {
            (user.versionID !== version.pro) && 
            <FormControl className={classes.formControl}>
            <InputLabel id="gst-select-outlined-label">GST</InputLabel>
            <Select
              id="gstTaxProfile-select"
              value={
                formState.values.gstTaxProfileIndex !== undefined
                  ? formState.values.gstTaxProfileIndex
                  : ""
              }
              onChange={handleChange}
              name="gstTaxProfileIndex"
            >
              {gstTaxProfiles?.map((gstTaxProfile, index) => (
                <MenuItem key={gstTaxProfile.id} value={index}>
                  {gstTaxProfile.taxProfileName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          }
          <DialogActions>
            <Button
              className={classes.signInButton}
              color="primary"
              disabled={( !formState.isValid || disabled)}
              size="large"
              type="submit"
              variant="contained"
              onClick={onSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ParentMaterial;
