import React, { useEffect, useState } from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import {  MaterialTable } from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    breadcrumbs: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },

    red: {
        color: '#f44336'
    },
    green:{
        color:'#4caf50'
    },
    button: {
        marginLeft: theme.spacing(2),
    },
    buttonMob: {
        marginLeft: theme.spacing(1),
    },
}));

const RawMaterialStorageView = ({ showError, showMessage, history,match }) => {
    const [rawMaterialStorage,setRawMaterialStorage] = useState([])
    const [loading, setLoading] = useState(false);

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });


    //Get all rawMaterialStorage API Call
    const getAllRawMaterialStorage = () => {
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.post(`/raw-material-storage`);

        response.then(res => {
            setRawMaterialStorage([...res])
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Raw Material Storage');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }

    const onload = () => {
        setLoading(true);
        Promise.all([getAllRawMaterialStorage()]).finally(() => {
            setLoading(false);
        });
    }
    useEffect(onload, []);

    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                   Raw material Storage Overview
                </Typography>
            </Grid>
            <Grid
                container
                justify={isDesktop?'flex-end':'center'}
                direction="row"
                alignItems='flex-end'
            >

            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}


            columns={[
                { title: 'id', field: 'id', hidden: true },
                { title: 'Material Name', field: 'materialName', hidden: false, editable: 'never' },
                { title: 'Available Quantity', field: 'availableStorage', hidden: false, editable: 'never' },
            ]}
            title='Raw Material Storage  Table'
            data={rawMaterialStorage}
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                toolbar: true
            }}
        />
    </div>
}

export default RawMaterialStorageView;