import React, { useEffect, useState, useRef } from 'react';
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import api from "../../common/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from 'html2canvas';
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import {useGlobalState} from '../../state';
import Divider from '@material-ui/core/Divider';
import TableWrapper from "../../components/Table/TableWrapper";

window.html2canvas = html2canvas;
const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    formControl: {
        width: '100%'
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    singleLineBorder: {
        borderTop: 'solid 1px #F26522',
        borderBottom: 'solid 1px #F26522'
    },
    doubleLineBorder: {
        borderTop: 'double #F26522',
        borderBottom: 'double #F26522'
    },
    boldedText: {
        fontWeight: 'bold'
    },
    reportContainer: {

    },
    reportHeader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    rawMaterialName: {
        textAlign: 'center',
        textDecoration: 'underline'
    }
}));

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: '1em',
        backgroundColor: theme.palette.common.white,
        padding: '6px'
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);


const tableHeader = JSON.parse(`[["Material Name","Purchase Date","Sale Date","Quantity In","In Rate","Quantity Out","Out Rate"]]`);




const generateExcel = function (header, data) {
    console.debug("excel ", header, data)
    let csvData = '';
    header.forEach((column, index) => {
        if (index === 0)
            csvData = csvData + column
        else
            csvData = csvData + ',' + column
    });
    csvData = csvData + '\r\n';
    data.forEach(row => {
        csvData = csvData + `${row[0]},${row[1]!== "0"?moment(row[1]).format("DD-MM-YYYY"):""},${row[2]!== "0"?moment(row[2]).format("DD-MM-YYYY"):""},${row[3]},${row[4]},${row[5]},${row[6]} \r\n`;
        
    });

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
    element.setAttribute('download', 'report.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);


}



const FarmStorageReport = function ({ showError, match }) {
    const theme = useTheme();
    const farmID = match.params['farmID'];

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);
    const [data, setData] = useState([]);
    const [wholedata, setWholeData] = useState([]);
    const [invoiceSetting, setInvoiceSetting] = useState({});

    useEffect(() => {
        if (startDate&& endDate && (startDate.getTime() < endDate.getTime())) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [startDate, endDate]);

    const getAllInvoiceSettings = () => {
        showError('');
        const response = api.get('invoiceSettings');
        response.then(res => {
            setInvoiceSetting(res[0]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No InvoiceSettings Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }
    const onLoad = () => {
        setLoading(true);
        Promise.all([getAllInvoiceSettings()]).finally(() => {
            setLoading(false);
        });
    }

    useEffect(onLoad, []);

    const parseReportFromServer = function (data) {
        const { reportsArray } = data;
        if(reportsArray !== null && reportsArray !== undefined){
            setData(reportsArray);
        } else {
            setData([])
        }
        setWholeData(data);
        console.log("parsing data", data.openingStorage)

    };

    const generateReport = function () {
        showError('');
        setLoading(true);
        const payload = {
            startDate: startDate,
            endDate: endDate
        };

        const response = api.post(`reports/farm-storage-report/${farmID}`, payload);

        response.then(res => {
            parseReportFromServer(res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });

    };

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });


    const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
    });
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }


    return <div className={classes.root}>
        <Grid
            container
            alignItems='flex-start'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Farm Storage Report
                </Typography>
            </Grid>
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container
                spacing={3}
                justify="flex-start"
                alignItems="center">

                <Grid item>
                    <KeyboardDatePicker
                        margin="normal"
                        autoOk
                        variant="inline"
                        label="From"
                        format="dd/MM/yyyy"
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
                <Grid item>
                    <KeyboardDatePicker
                        margin="normal"
                        variant="inline"
                        autoOk
                        label="To"
                        format="dd/MM/yyyy"
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
                <Grid item>
                    <Button disabled={!valid} variant="contained" color="primary"
                        onClick={generateReport}>Generate</Button>
                </Grid>
                <Grid item>
                    <Button disabled={!valid || data.length < 1} variant="outlined" color="primary"
                        onClick={generatePDF} endIcon={<PictureAsPdfIcon />}>Download
                        PDF</Button>
                </Grid>
                <Grid item>
                    <Button disabled={!valid || data.length < 1} variant="outlined" color="primary"
                        onClick={() => generateExcel(tableHeader, data)}
                        endIcon={<GetAppIcon />}>Download Excel</Button>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
        
        {loading ?
            <div className={classes.progressContainer}>
                <CircularProgress className={classes.progressBar} />
            </div>
            :
            <div id="customer-invoice" ref={componentRef} style={{ paddingTop: '35px' }}>
                <div className="invoice-box" style={{
                    backgroundColor: '#ffffff',
                    padding:isDesktop ? "50px":"10px",
                    fontFamily: 'Helvetica Neue, Helvetica, Helvetica, Arial, sans-serif'
                }}>
                    <table cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr >
                                <td>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ width: "40%" }}><img src={invoiceSetting.companyLogo}
                                                                    style={{ //width: '50%', 
                                                                        maxWidth: '130px'
                                                                    }}
                                                                    alt="company logo"></img><br /><br />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style={{ textAlign: "right" }}>
                                                    <table style={{ width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ textAlign: "right", fontWeight: '600', fontSize: '23px' }} colSpan="2">
                                                                    Farm Storage Report<br />
                                                                    <span style={{ fontWeight: '400', fontSize: '15px' }}>
                                                                        <b>From : </b> {moment(startDate).format('MMM Do YYYY')}
                                                                    </span><br/>
                                                                    <span style={{ fontWeight: '400', fontSize: '15px' }}>
                                                                        <b>To : </b> {moment(endDate).format('MMM Do YYYY')}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2" >
                                                                    <Divider />
                                                                    <br />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ textAlign: "right" }}>
                                                                    Opening Stock
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {wholedata.openingStock !== undefined ? wholedata.openingStock: 0}
                                                                </td>
                                                            </tr>
                                        
                                                            <tr style={{ fontWeight: 'bold' }}>
                                                                <td style={{ textAlign: "right" }}>
                                                                    Closing Stock
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {wholedata.closingStock !== undefined ? wholedata.closingStock : 0}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <TableWrapper enableKeyboardNavigation={true} focus={data && data.length > 0}>
                        <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell>Material Name</StyledTableCell>
                                        <StyledTableCell>Sale Date</StyledTableCell>
                                        <StyledTableCell>Purchase Date</StyledTableCell>
                                        <StyledTableCell>In Quantity</StyledTableCell>
                                        <StyledTableCell>In Rate</StyledTableCell>
                                        <StyledTableCell>Out Quantity</StyledTableCell>
                                        <StyledTableCell>Out Rate</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">
                                                {row[0]}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {row[1] !== "0" ?moment(row[1]).format("DD-MM-YYYY"):""}  
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {row[2] !== "0"?moment(row[2]).format("DD-MM-YYYY"):""}
                                            </StyledTableCell>
                                            <StyledTableCell className={
                                                row[0].includes('Net')
                                                    ? classes.boldedText : undefined
                                            } align="left"> {parseFloat(row[3])}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {parseFloat(row[4]).toLocaleString(user.locales,amountObj)}
                                            </StyledTableCell>
                                            <StyledTableCell className={
                                                row[0].includes('Net')
                                                    ? classes.boldedText : undefined
                                            } align="left"> {parseFloat(row[5])}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {parseFloat(row[6]).toLocaleString(user.locales,amountObj)}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>

                       </TableWrapper>
                </div>
            </div>
        }
    </div>
}

export default FarmStorageReport;