import React, { useEffect, useState } from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import RawMaterialStorageStock from './components/RawMaterialStock'
import Icon from '@material-ui/core/Icon';
import moment from 'moment'
import Mobile from "./components/mobile";

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));


const RawMaterialStorageView = ({ showError, showMessage, history, match }) => {

    const [RawMaterialStorageStockData, setRawMaterialStorageData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentRawMaterialStorage,setCurrentRawMaterialStorage] =useState({})
    const [showRawMaterialStorageDialog, setShowRawMaterialStorageDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [editMode,setEditMode] = useState(false)
    const [rawMaterials,setRawMaterials] = useState([])
    const [availableStorage,setAvailableStorage] = useState(0)
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });

    function onNewRawMaterialStorageDatas(event) {
        setEditMode(false)
        setErrorMessage("");
        event.preventDefault();
        setShowRawMaterialStorageDialog(true);
    }

    function handleMedicineStockClose() {
        setErrorMessage(null);
        setCurrentRawMaterialStorage({});
        setShowRawMaterialStorageDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const getAllRawMaterialName = function (materialId) {
    showError("");
    const response = api.post(
        `/raw-material-list-for-material-type/1`,{
        isFeedMill:true
        }
    );
    response
        .then((res) => {
        setRawMaterials([...res]);
        setLoading(false)
        })
        .catch((err) => {
        if (err.message) {
            if (err.message === 'Bad Request' || err.message === 'No data found')
                console.log("No Raw Material Found")
        } else {
            showError('Unknown Error')
        }
        })
        .finally(() => {
        setLoading(false)
        });
    return response;
    };

    const getAvailableRawMaterialStorageByID = function (materialId,date,storageID) {
        showError("");
        const response = api.post(
            `/available-raw-material-storage/${materialId}`,
            {   
                date,
                id:storageID
            }
        );
        response
            .then((res) => {
            let availableStock = res?.availableStorage

            setAvailableStorage(availableStock);
            setLoading(false)
            })
            .catch((err) => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log("No Raw Material Found")
            } else {
                showError('Unknown Error')
            }
            })
            .finally(() => {
            setLoading(false)
            });
        };
    


    const onRawMaterialStorageDeleteConfirm = () => {
        if (currentRawMaterialStorage && currentRawMaterialStorage.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`/move-to-inventory-raw-material-storage/${currentRawMaterialStorage.id}`);

            response.then(res => {
                if (res) {
                    let index = RawMaterialStorageStockData.findIndex((srcMedicineStock) => {
                        return srcMedicineStock.id === currentRawMaterialStorage.id;
                    });
                    let newStorageStockData = [...RawMaterialStorageStockData];
                    newStorageStockData.splice(index, 1);
                    setRawMaterialStorageData(newStorageStockData);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('RawMaterialStorageStockData not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                setCurrentRawMaterialStorage({})
            });

        }
    };

    const removeErrorMessage = value => {
        setErrorMessage('');
    }

    const onRawMaterialStorageDataDelete = (rawMaterialStorageData) => {
        setCurrentRawMaterialStorage(rawMaterialStorageData)
        setShowDeleteConfirm(true);
    };

    const onRawMaterialStorageDatasSave = (rawMaterialStorageData, setpopuploading) => {
        showMessage('');
        const response = api.post('/move-to-inventory-raw-material-storage', rawMaterialStorageData);

        response.then(res => {
            let { name } = rawMaterials.find((el) => el.id === res.rawMaterialId)

            res.rawMaterialName = name
            setRawMaterialStorageData([...RawMaterialStorageStockData, res]);
            setShowRawMaterialStorageDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found') {
                    setErrorMessage('RawMaterialStorageStockData not added');
                }
                else if (err.message === 'duplicate key') {
                    setErrorMessage("Duplicate entry. Medicine Stock already exists")
                } else if (err.message === 'no batch found') {
                    setErrorMessage(`No batch found for shed ${rawMaterialStorageData.shedName} on ${moment(rawMaterialStorageData.date).format("DD-MM-YYYY")}`)
                } else if (err.message === 'no stock') {
                    setErrorMessage('Material with available stock 0 cannot be added');
                }
                else {
                    setErrorMessage(err.message)
                }
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };


    const onRawMaterialStorageDataEdit = async (rawMaterialStorageData) => {
        try {
            setErrorMessage("");
    
            await getAvailableRawMaterialStorageByID(rawMaterialStorageData.rawMaterialId,rawMaterialStorageData.date,rawMaterialStorageData.id);
            const tempCurrentRawMaterialStorage = {
                ...rawMaterialStorageData,
                rawMaterialIdx: rawMaterials?.findIndex((formula) => formula.id === rawMaterialStorageData.rawMaterialId),
            };
    
            setCurrentRawMaterialStorage(tempCurrentRawMaterialStorage);
            setEditMode(true);
            setShowRawMaterialStorageDialog(true);
            setLoading(false)
        } catch (error) {
            setErrorMessage("An error occurred while editing raw material storage data.");
            console.error(error);
            setLoading(false)
        }
    };

    const onRawMaterialStroageUpdate = (rawMaterialStorageData, setPopupLoading) => {
        const response = api.post(`/move-to-inventory-raw-material-storage/${rawMaterialStorageData.id}`, rawMaterialStorageData);
        response.then((res) => {
            let index = RawMaterialStorageStockData.findIndex((srcStorageData) => {
                return srcStorageData.id === rawMaterialStorageData.id;
            });
            setRawMaterialStorageData([...RawMaterialStorageStockData.slice(0, index),
            { ...res },
            ...RawMaterialStorageStockData.slice(index + 1)]);

            setShowRawMaterialStorageDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'Not Updated') {
                    setErrorMessage('Raw MAterial Storage not Updated');
                } else if (err.message === 'no stock') {
                    setErrorMessage('Formula with available stock 0 cannot be updated');
                }
                else {
                    setErrorMessage(err.message)
                }
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setPopupLoading(false)
            setLoading(false);
        })
    };

    //Get all RawMaterialStorageStockData API Call
    const getAllRawMaterialStorageData = () => {
        setShowRawMaterialStorageDialog(false);
        showError('');
        showMessage('');

        const response = api.post(`/get-all-raw-material-storage`,{});

        response.then(res => {
            setRawMaterialStorageData([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Raw material Stock Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }

    const onload = () => {
        setLoading(true);
        Promise.all([getAllRawMaterialName(),getAllRawMaterialStorageData()]).finally(() => {
            setLoading(false);
        });
    }
    useEffect(onload, []);
    let formulaColumnDefaults = { title: 'Material Name', field: 'rawMaterialName', defaultSort: 'asc' }
    let formulaColumn = {
        render: rowData => <Mobile data={rowData}
            onEdit={() => { onRawMaterialStorageDataEdit(rowData) }}
            onDelete={() => { onRawMaterialStorageDataDelete(rowData) }}
        />
    }
    let actions = [];
    if (isDesktop) {
        formulaColumn = formulaColumnDefaults
        actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Inventory storage',
                onClick: (event, rowData) => onRawMaterialStorageDataEdit(rowData)
            },
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Inventory storage',
                onClick: (event, rowData) => onRawMaterialStorageDataDelete(rowData)
            }
        ]
    }


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Move to Inventory
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewRawMaterialStorageDatas}>Add Move to Inventory</Button>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'No', field: 'id', hidden: !isDesktop },
                {
                    title: 'Date', field: 'date', hidden: !isDesktop,
                    render: rowData => {
                        return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
                    }
                },

                formulaColumn,
                { title: 'Quantity', field: 'quantity', hidden: !isDesktop, editable: 'never' },
                {
                    title: 'Cost Per Unit', field: 'costperunit', hidden: !isDesktop,
                    render: rowData => rowData.costperunit.toFixed(2)
                },
                {
                    title: 'Cost', field: 'cost', hidden: !isDesktop,
                    render: rowData => rowData.cost.toFixed(2)
                },
            ]}
            data={RawMaterialStorageStockData}
            title='Raw Material storage Data Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,

                toolbar: true
            }}
            actions={actions}
        />
        <RawMaterialStorageStock errorMessage={errorMessage} showDialog={showRawMaterialStorageDialog} handleClose={handleMedicineStockClose} onSave={onRawMaterialStorageDatasSave} onEdit={onRawMaterialStroageUpdate}
            editMode={editMode} data={currentRawMaterialStorage} deleteErrorMessage={removeErrorMessage} match={match}  
            rawMaterials={rawMaterials}
            availableStorage={availableStorage}
            getAvailableRawMaterialStorageByID={getAvailableRawMaterialStorageByID}
        />
        <ConfirmDialog showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onRawMaterialStorageDeleteConfirm}
            title='Delete Inventory Storage ?'
            subText='This action will delete the Inventory Storage Details. Please confirm.' />
    </div>
}


export default RawMaterialStorageView;