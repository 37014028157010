import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FarmCard from './components/FarmCard';
import Farm from './components/Farm';
import api from '../../common/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import {ConfirmDialog} from '../../components';
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useGlobalState} from '../../state';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FarmCardDetails from './components/FarmCardDetails';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'
import { Container } from '@material-ui/core';
import { version } from '../../common/enums';

const useStyles = makeStyles(theme => ({
    root: props=>({
        marginLeft: props.isDesktop?theme.spacing(1):theme.spacing(1),
        marginRight: props.isDesktop?theme.spacing(4):theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    pagesBox:{
          padding: theme.spacing(5),
          marginBottom:theme.spacing(5)
    },
    pagesBoxLite:{
          paddingTop: theme.spacing(20),
          marginBottom:theme.spacing(5)
    },
    ContainerBox :{
        textAlign: "center",
        marginTop:theme.spacing(3),
        marginBottom:theme.spacing(3)
    }


}));

const farmReport = {
    eggsPerBag: 0,
    farmId: 0,
    feedIntake: 0,
    feedIntakePerGram: 0,
    prodution: 0,
    produtionPercentage: 0,
    sales: 0,
}

const farmReportsState = {
    eggsPerBag: 0,
    farmId: 0,
    feedIntake: 0,
    feedIntakePerGram: 0,
    prodution: 0,
    produtionPercentage: 0,
    sales: 0,
    date: "",
    allShedData: [
        {
            batchName: "",
            shedName: "",
            feedIn: 0,
            eggSales: 0,
            eggs: 0,
            age: 0,
            productionPercentage: 0,
            closingBirds: 0,
            isShedDataUsed: false,
            feedPerBird:0
        }
    ],
    usedShedData: [
        {
            batchName: "",
            shedName: "",
            feedIn: 0,
            eggSales: 0,
            eggs: 0,
            age: 0,
            productionPercentage: 0,
            closingBirds: 0,
            isShedDataUsed: false,
            feedPerBird:0
        }
    ],
}

const weatherDataState = {
    id: 0,
    farmID: 0,
    latitude: 0,
    longitude: 0,
    weatherDate: "",
    minTemp: 0,
    maxTemp: 0,
    iconCode: "04d"
}

let currentFarm = undefined;
let editMode = false;
const Dashboard = ({showError,history,isMainDash=false,multiFarmDash}) => {
    const [{user}, dispatch] = useGlobalState();

    const [farms, setFarms] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [chosenAccount,setChosenAccount] = useState(0);
    const [farmReports, setFarmReports] = useState({farmReportsState});
    const [farmWeeklyReports,setFarmWeeklyReports] = useState({});
    const [currentFarmReport,setCurrentFarmReport] = useState({});
    const [loading, setLoading] = useState(false);
    const [showFarmDialog, setShowFarmDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showFarmCardDetailDialog, setshowFarmCardDetailDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [weatherDatas, setWeatherDatas] = useState([weatherDataState])
    const [dateAndTime, setDateAndTime] = useState("")
    const theme = useTheme();
    

    useEffect(()=>{
        if(multiFarmDash) {
             //multiFarm
             let tempfarm = farms
             if (tempfarm.length > 1) {
                 multiFarmDash(true)
             } else {
                multiFarmDash(false)
             }
        }
    },[farms,multiFarmDash])

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    const handleChange = (event) => {
        const accountID = parseInt(event.target.value, 10);
        setChosenAccount(accountID)
        getAllFarmsForAccount(accountID)
    };

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }
       
    const getAllFarmsForAccount = (accountID) =>{
        setLoading(true);
        showError('');
        const response = api.post('getAllFarmsForAccount', {id:accountID});
        response.then(res => {
            //Set User Profile
            dispatch({
                type: 'setProfile',
                user: res.profile
            });
            setFarms([...(res.farms)]);
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Farms Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        response.finally(() => {
            setLoading(false);
        });
        return response
    }

    const getAllFarms = () => {
        setLoading(true);
        showError('');
        const response = api.get('farm');
        response.then(res => {
            setFarms([...res]);
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Farms Found');
                else
                    showError("Server is down. Please try again in a few minutes")
            } else {
                showError('Unknown Error')
            }
        });
        return response
    };

    const getAllAccounts = () => {
        setLoading(true);
        showError('');
        const response = api.get('getAllAccounts');
        response.then(res => {
            setAccounts([...res]);
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Accounts Found');
                else
                    showError("Server is down. Please try again in a few minutes")
            } else {
                showError('Unknown Error')
            }
        });
        return response
    };

    function tConvert (time) {
        // Check correct time format and split into components
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      
        if (time.length > 1) { // If time format correct
          time = time.slice (1);  // Remove full string match value
          time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
          time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join (''); // return adjusted time or original string
    }

    const getAndSetLastFetchedWeatherDateTime = (weather) => {
        
        const dateAndTime = weather.weatherDate
        
        const date = new Date(weather.weatherDate).toLocaleDateString()
        let timeIn24Hour = dateAndTime.substring(11, 19)
        timeIn24Hour = timeIn24Hour.replaceAll(":",".")

        const time = tConvert(timeIn24Hour)

        const message = `Weather information fetched at ${time.substring(0, 5)} on ${date}`
        setDateAndTime(message)
    }

    const getWeatherDataForAllFarms = () => {
        setLoading(true);
        showError("");
        const response = api.get("/getWeatherDataForAllFarms");
        response.then(res => {
            setWeatherDatas([...res]);
            getAndSetLastFetchedWeatherDateTime(res[0])
        })
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Weather data not found');
                else
                    console.debug("error",err)
                    
            } else {
                showError('Unknown Error')
            }
        });
        response.finally(() => {
            setLoading(false);
        });
    }

    const getAllFarmsDashboardReport = () => {
        setLoading(true);
        showError('');
        const farmEndPoint = (isMainDash || (user.versionID === version.lite) ||  (user.versionID === version.pro)) ? 'getAllFarmsDashboardReportWeekly':'getAllFarmsDashboardReport';
        const response = api.get(farmEndPoint);
        response.then(res => {
            if(isMainDash || (user.versionID === version.lite) || (user.versionID === version.pro)){
                var dailyReport = {};
                for(var i in res){
                    dailyReport[i] = res[i][Object.keys(res[i]).length]; // Only get latest one day data from each farm
                }
                setFarmReports(dailyReport);
                setFarmWeeklyReports({...res});
            }
            else{
                setFarmReports({...res});
            }
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Farm Data Found');
                else
                    showError("Server is down. Please try again in a few minutes")
            } else {
                showError('Unknown Error')
            }
        });
        return response
    };

    //Edit / new Farm Dialog Functions
    function onNewFarm(event) {
        editMode = false;
        event.preventDefault();
        setShowFarmDialog(true);
    }

    function handleFarmClose() {
        currentFarm = undefined;
        setErrorMessage(null);
        setShowFarmDialog(false);
    }

    function handleFarmCardDetailClose() {
        setshowFarmCardDetailDialog(false);
    }

    const onFarmSave = (farm, setPopupLoading) => {
        // setLoading(true);
        console.log("FARM", farm);
        showError('');
        const response = api.post('farm', {
            ...farm,
            pincode: parseInt(farm.pincode, 10)
        });
        response.then(res => {
            setFarms([...farms, res]);
            if(user.versionID!==version.lite){
                getWeatherDataForAllFarms()
            }
            setShowFarmDialog(false);
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Farm not added');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        });
        response.finally(() => {
            setLoading(false);
            setPopupLoading(false);
        });
    };

    const onFarmUpdate = (farm, setPopupLoading) => { 
        // setLoading(true)
        showError('');
        const response = api.post(`farm/${farm.id}`, {
            ...farm,
            pincode: parseInt(farm.pincode, 10)
        });
        response.then(() => {
            let index = farms.indexOf(currentFarm);
            setFarms([...farms.slice(0, index),
                {...farm},
                ...farms.slice(index + 1)]);
            if(user.versionID!==version.lite){
                getWeatherDataForAllFarms();
            }
            setShowFarmDialog(false);
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('No Farms Found');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        });
        response.finally(() => {
            setPopupLoading(false);
            setLoading(false);
        });
    };
    //Delete Farm Dialogs
    const onFarmDelete = (farm) => {
        currentFarm = farm;
        setShowDeleteConfirm(true);
    };

    const onFarmDetails = (farm,thisFarmDetails=false) => {
        currentFarm = farm
        var newFarmReports = {...farmReports};
        if(newFarmReports[farm.id] === undefined){
            newFarmReports[farm.id] = farmReportsState;
        }
        
        if(thisFarmDetails && thisFarmDetails[farm.id] !== undefined){
            newFarmReports[farm.id] = thisFarmDetails[farm.id];
        }

        if(isMainDash){
            setCurrentFarmReport(newFarmReports);
        } else {
            setFarmReports(newFarmReports);
        }
        setshowFarmCardDetailDialog(true);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const onFarmDeleteConfirm = () => {
        if (currentFarm && currentFarm.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`farm/${currentFarm.id}`);
            response.then(res => {
                if (res) {
                    let index = farms.indexOf(currentFarm);
                    let newFarms = [...farms];
                    newFarms.splice(index, 1);
                    setFarms(newFarms);
                    //weather fetch msg del
                    if (newFarms.length === 0) {
                        setDateAndTime("")
                    }
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Farm not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentFarm = undefined;
            });
        }

    };



    //Farm Edit Functions
    const onFarmEdit = (farm) => {
        currentFarm = farm;
        editMode = true;
        setShowFarmDialog(true);
    };
    const onDashBoardChangeRequest = (farm)=>{
        if(user.versionID!==version.lite){
            setLoading(true)
            getAllFarmsDashboardReport().then((res)=>{
                if(isMainDash){
                    var dailyReport = {};
                    for(var i in res){
                        dailyReport[i] = res[i][Object.keys(res[i]).length]; // Only get latest one day data from each farm
                    }
                    setFarmReports(dailyReport);
                    setFarmWeeklyReports({...res});
                    setCurrentFarmReport({...dailyReport});
                }
            }).finally(()=>{
                setLoading(false)
            });
        }
    }
    const onload=()=>{
        setLoading(true);
        let apiFetch=[];
        if(user.versionID!==version.lite){
            apiFetch = [getAllFarms(),getAllFarmsDashboardReport(), getWeatherDataForAllFarms()];
        }
        else{
            apiFetch = [getAllFarms(),getAllFarmsDashboardReport()];
        }
        if(user.role === "ADMIN") {
            Promise.all(apiFetch).finally(()=>{
                setLoading(false);
            });
        }
        else if(user.role === "DOCTOR"){
            Promise.all([getAllAccounts()]).finally(()=>{
                setLoading(false);
            }); 
        }
        else if(user.role === "SUPER"){
            Promise.all(apiFetch).finally(()=>{
                setLoading(false);
            }); 
        }else if(user.role === "FEEDSUPER") {
            Promise.all(apiFetch).finally(()=>{
                setLoading(false);
            }); 
        }
        else if(user.role === "STAFF"){
            Promise.all(apiFetch).finally(()=>{
                setLoading(false);
            }); 
        }
    
    }
    useEffect(onload, []);
    
    return (
        <div className={classes.root}>
            <Grid
                container
                justify='space-between'
                alignItems='flex-end'
                className={classes.topBar}
            >
                 <Grid>
                    {
                        (farms?.length > 0 && user.role ==="ADMIN")?
                            <Button variant="contained" color="primary" onClick={onNewFarm}>Add Farm</Button>  
                        : (
                            user.role ==="DOCTOR"?
                            <FormControl  
                                className={classes.formControl}
                                style={{minWidth: 120}}>
                            <InputLabel id="type-select-outlined-label">
                            Account
                            </InputLabel>
                            <Select
                            id="chosenAccount"
                            value={chosenAccount !== undefined ? chosenAccount : ''}
                            onChange={handleChange}
                            name="chosenAccount"
                            >
                                {accounts !== undefined && accounts !== null ?
                                accounts.map((account)=>
                                <MenuItem key = {account.id} value={account.id}>{account.companyName}</MenuItem>
                            ):""}     
                            </Select>
                            <br/>
                        </FormControl>
                            :""
                        )
                    }
                    {
                        user.versionID!==version.lite ?        
                        <Typography component="div" variant="body1">
                            <Box mt={2} color="text.secondary">{dateAndTime}</Box>
                        </Typography>
                        :
                        ''    
                    }
                </Grid>
                

               
            </Grid>
            {
                loading ?
                    <div className={classes.progressContainer}>
                        <CircularProgress className={classes.progressBar}/>
                    </div> :
                    <Grid
                        container
                        spacing={3}
                    >
                        {
                            farms.map((farm, index) => {
                                return <FarmCard history={history} key={farm.id} 
                                farmReport={farmReports[farm.id] ? farmReports[farm.id] : farmReport} 
                                farm={farm} onDelete={onFarmDelete} onEdit={onFarmEdit} onFarmDetails={onFarmDetails}
                                weatherData={weatherDatas[index] !== undefined ? weatherDatas[index]: {id: 0, farmID: 0, farmLocation: 0, weatherDate: "", minTemp: 0, maxTemp: 0, iconCode: "04d"}}
                                weeklyData={farmWeeklyReports[farm.id] ? farmWeeklyReports[farm.id] : farmReport}
                                isMainDash={isMainDash}
                                totalFarms={farms.length}
                                />
                            })
                        }
                    </Grid>
            }

            {
                farms.length === 0  && !loading && user.role ==="ADMIN" ? 
                <div className={(user.versionID !==version.proPlus) ? classes.pagesBoxLite : ""}>
                <div className={classes.pagesBox}>
                    <Container>
                        <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div className={classes.ContainerBox}>
                            <Typography variant="h1" component="h1" gutterBottom>
                                No Farm
                            </Typography>

                            <Typography variant="body1" gutterBottom>
                                Please Create A Farm To Continue
                            </Typography>
                            <div className={classes.ContainerBox}>
                            {
                                        (user.role ==="ADMIN")?
                                            <Button variant="contained" color="primary" onClick={onNewFarm}>Add Farm</Button>  
                                        : ""
                                        
                            }
                            </div>
                            </div>
                        </Grid>
                        </Grid>
                    </Container>
                    </div>
                </div>
                
                :""
            }
            <Farm deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showFarmDialog} handleClose={handleFarmClose} onSave={onFarmSave} onEdit={onFarmUpdate}
                  editMode={editMode} farm={currentFarm}
                  loading={loading}/>
            <ConfirmDialog showDialog={showDeleteConfirm}
                           handleClose={handleDeleteConfirmClose}
                           onConfirm={onFarmDeleteConfirm}
                           title='Delete Farm ?'
                           subText='This action will delete the farm and all data associated with it.Please confirm.'/>
            <FarmCardDetails showDialog={showFarmCardDetailDialog} farm={(isMainDash) ? currentFarmReport: farmReports} currentFarm={currentFarm}
                        handleClose={handleFarmCardDetailClose} onDashBoardChangeRequest={onDashBoardChangeRequest}
                        loading={loading}
                       />
        </div>
    );
};

export default Dashboard;