import React, { useState, useRef } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import api from "../../common/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from "html2canvas";
import useFromValidator from '../../common/formValidator';
import { useReactToPrint } from 'react-to-print';
import {useGlobalState} from '../../state';
import {changeToLocalString } from '../../common/functions';

import { version } from "../../common/enums";
import { Redirect } from "react-router-dom";
import TableWrapper from "../../components/Table/TableWrapper";
import { generateReportTitle } from "./utils/functions";

window.html2canvas = html2canvas;

const schema = {    
    entryDate:{
        presence: {allowEmpty: false, message: 'is required'}
    }
};

const useStyles = makeStyles(theme => ({
  root: props => ({
    margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
  }),
  topBar: {
    marginBottom: theme.spacing(2)
  },
  link: {
    display: "flex"
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  formControl: {
    width: "100%"
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  progressBar: {
    margin: theme.spacing(2)
  },
  singleLineBorder: {
    borderTop: "solid 1px #F26522",
    borderBottom: "solid 1px #F26522"
  },
  doubleLineBorder: {
    borderTop: "double #F26522",
    borderBottom: "double #F26522"
  },
  reportContainer: {},
  reportHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  rawBatchName: {
    textAlign: "center",
    textDecoration: "underline"
  },
  topic: {
    alignItems: "center",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  tableHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
  }
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding:"6px",
    overflow: "hidden", 
    width:"90px"
  },
  body: {
    fontSize: "1em",
    backgroundColor: theme.palette.common.white,
    textAlign:"center",
    padding:"6px", 
    overflow: "hidden",  
    width:"90px" 
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    },
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)"
    }
  }
}))(TableRow);

const tableHeader = JSON.parse(
  `[["Date","Opening Birds", "Mortality", "Bird Closing", "Egg Opening", "Egg Production", "Egg Sales", "Egg Closing","Broken Egg Opening","Egg Cage Broken","Egg Truck Broken", "Broken Egg sales", "Broken Egg Closing",
  "Daily cage Damage","Truck Damage","Feed Opening", "Feed Received", "Feed Intake", "Feed Closing", "In Value", "Out Value"]]`
);

const generateExcel = function(header, data, fileName) {
  let csvData = "";
  header.forEach((column, index) => {
    if (index === 0) csvData = csvData + column;
    else csvData = csvData + "," + column;
  });
  csvData = csvData + "\r\n";
  data.forEach(row => {
    row.forEach((column, index) => {
      if (index === 0) csvData = csvData + column;
      //else if (index === 1) csvData = csvData + "";
      else csvData = csvData + "," + column;
    });
    csvData = csvData + "\r\n";
  });

  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csvData)
  );
  element.setAttribute("download", `${fileName}.csv`);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

const AllBatchStock = ({ showError, match }) => {
  const theme = useTheme();
  const [{user}] = useGlobalState();
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [data, setData] = useState([]);
    const [entryDate, setEntryDate] = useState(null);
    const [reportFileName, setReportFileName] = useState("");

  const [formState, hasError, handleChange] = useFromValidator(schema);


  const parseReportFromServer = function(data) {
    const { reportsArray } = data;
    setData(reportsArray);
    console.log(data);
  };

  const generateReport = function() {
    showError("");
    setLoading(true);
    const payload = {
      //batchID: batches[batchIndex].id
      farmID: parseInt(match.params["farmID"]),
      date: formState.values.entryDate
    };

    const response = api.post("reports/all-batch-stock", payload);

    response
      .then(res => {
        parseReportFromServer(res);
        
        setReportFileName(generateReportTitle({date: formState.values.entryDate, reportName: "AllBatchStock"}));
      })
      .catch(err => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            showError(err.message);
        } else {
          showError("Unknown Error");
        }
      })
      .finally(() => {
        setLoading(false);
        setEntryDate(new Date(payload.date))
      });
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  });
  const classes = useStyles({ isDesktop });
 
    // const amountObj = {
    //     style: "currency",
    //     currency: user.currency,
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2
    //   }
  
  const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: reportFileName,
        pageStyle: `
          @media print {
            @page {
              size: landscape !important;
              margin: 0;
            }
          }`,
      });

      //ABSR will be visible to pro and pro plus version

  if(user.versionID === version.lite){
        return(
            <Redirect to="/not-found" />
        );
  }

  return (
    <div className={classes.root}>
      <Grid container alignItems="flex-start" className={classes.topBar}>
        <Grid>
          <Typography display="block" variant="h3" gutterBottom>
            All Batch Stock Report 
          </Typography>
        </Grid>
      </Grid>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={3} justify="flex-start" alignItems="center">
          <Grid item>
                <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Entry Date"
                    format="dd/MM/yyyy"
                    value={formState.values.entryDate || null}
                    onChange={data => {
                        setValid(true);
                        handleChange({target: {name: 'entryDate', value: data}});
                    }}
                    onFocus={e => e.target.blur()} 
                    animateYearScrolling
                    name="entryDate"
                    error={hasError('entryDate')}
                />
          </Grid>
          <Grid item>
            <Button
              disabled={!valid}
              variant="contained"
              color="primary"
              onClick={generateReport}
            >
              Generate
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!valid || data.length < 1}
              variant="outlined"
              color="primary"
              onClick={generatePDF}
              endIcon={<PictureAsPdfIcon />}
            >
              Download PDF
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!valid || data.length < 1}
              variant="outlined"
              color="primary"
              onClick={() => generateExcel(tableHeader, data, reportFileName)}
              endIcon={<GetAppIcon />}
            >
              Download Excel
            </Button>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      {loading ? (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.progressBar} />
        </div>
      ) : (
        <div id="report-container" className={classes.reportContainer} ref={componentRef} >
          <div className={classes.reportHeader}>
            <Typography
              variant="h4"
              component="h4"
              className={classes.rawBatchName}
            >
                All Batch Stock Report {entryDate !== null ? "" + (entryDate.getDate()) + "/"+(entryDate.getMonth()+1) + "/" + entryDate.getFullYear() : ""}
            </Typography>
          </div>
          <TableWrapper enableKeyboardNavigation={true} focus={data && data.length > 0}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={classes.tableHeader}>
            <StyledTableRow
             align="center" >
                <StyledTableCell colSpan={1}></StyledTableCell>
                <StyledTableCell  align="center" colSpan={3}>Birds</StyledTableCell>
                <StyledTableCell  align="center" colSpan={4}>Good Eggs</StyledTableCell>
                <StyledTableCell align="center" colSpan={5}>Broken Eggs</StyledTableCell>
                <StyledTableCell align="center" colSpan={2}>Damaged</StyledTableCell>
                <StyledTableCell align="center" colSpan={4}>Feed</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
              {/* <StyledTableCell>Batch</StyledTableCell> */}
              <StyledTableCell>Shed</StyledTableCell>
              {/* <StyledTableCell>Date</StyledTableCell> */}
              <StyledTableCell>Opening</StyledTableCell>
              <StyledTableCell>Mortality</StyledTableCell>
              <StyledTableCell>Closing</StyledTableCell>
              <StyledTableCell>Opening</StyledTableCell>
              <StyledTableCell>Production</StyledTableCell>
              <StyledTableCell>Sales</StyledTableCell>
              <StyledTableCell>Closing</StyledTableCell>
              <StyledTableCell>Opening</StyledTableCell>
              <StyledTableCell>Cage</StyledTableCell>
              <StyledTableCell>Truck</StyledTableCell>
              <StyledTableCell>Sales</StyledTableCell>
              <StyledTableCell>Closing</StyledTableCell>
              <StyledTableCell>Daily</StyledTableCell>
              <StyledTableCell>Truck</StyledTableCell>
                <StyledTableCell>Opening</StyledTableCell>
                <StyledTableCell>Received</StyledTableCell>
                <StyledTableCell>In Take</StyledTableCell>
                <StyledTableCell>Closing</StyledTableCell>     
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <StyledTableRow key={index}>
                {row.map((cols, col) => { 
                     if (col ===1 || col ===20 || col===21){
                       return null;
                     }
                  return(
                    <StyledTableCell align="left" key = {col}
                    className={
                        row[0] === "" ? classes.singleLineBorder : undefined
                      }>
                      {
                              (
                              (!isNaN(cols) && cols.toString().indexOf('.') !== -1)?
                              changeToLocalString(cols, user.locales)
                              :
                              cols.toLocaleString(user.locales))
                      }
                    </StyledTableCell>
                    )
  
                  })}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          </TableWrapper>
          <Grid  className={classes.topic} item xs={isDesktop ? 6 : 12}>
          <Typography
              variant="h4"
              component="h4"
              className={classes.rawBatchName}
            > Double & Dirty Eggs
            </Typography>
            <TableWrapper enableKeyboardNavigation={true} >
           <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Shed</StyledTableCell>
                <StyledTableCell align="center">Double Eggs</StyledTableCell>
                <StyledTableCell align="center">Dirty Eggs</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
            {data.map((row, index) => (
                <StyledTableRow key={index}>
                {row.map((cols, col) => {    
                        if(col ===20){
                          if(row[20]!=='0' || row[21]!=='0'){
                            return(
                              <>
                               <StyledTableCell>{row[0]}</StyledTableCell>
                              <StyledTableCell>{row[20]}</StyledTableCell>
                              <StyledTableCell>{row[21]}</StyledTableCell>
                              </>
                              )
                          }
                                    
                     } 
                    return <></>     
                  })}
                </StyledTableRow>
              ))}

            </TableBody>
          </Table>
          </TableWrapper>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default AllBatchStock;
