import React, {useEffect,useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useGlobalState } from '../../../state';
import { version } from '../../../common/enums';

const schema = {
    materialCode: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    materialName: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    /*openingStock: {
        presence: {allowEmpty: false, message: 'is required'},
        type: 'integer'
    },
    openingValue: {
        presence: {allowEmpty: false, message: 'is required'},
        type: 'integer'
    },
    storageLoss: {
        presence: {allowEmpty: false, message: 'is required'},
        type: 'number',
        numericality: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 100
        }
    },
    minimumStockValue: {
        presence: {allowEmpty: false, message: 'is required'},
        type: 'integer'
    },*/
    materialTypeIndex: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    unit: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    inventoryType: {
        presence: {allowEmpty: false, message: 'is required'}
    },
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '9999'
    },
}));

const MaterialMaster = ({ deleteErrorMessage, errorMessage, onEdit, data, onSave,materialUnitTypes, handleClose, showDialog, editMode = false, materialTypes}) => {
    const [{user}] = useGlobalState();
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
    const [showStockFeilds, setShowStockFeilds] = useState(true);
    const [popupLoading, setPopupLoading] = useState(false);
    const [InventoryFarm, setInventoryFarm] = useState(false);
    const [infoMessage,setInfoMessage] = useState('')
    const resetBeforeEdit = () => {
        if(showDialog){
    
            if(data?.isDefault ===1){
                setInfoMessage("This material is auto created by the system and is not editable")
                
            }else{
                setInfoMessage("")
            }
            setPopupLoading(false);
            updateData(data, editMode);

            //MaterialType ID (6 = Eggs) (8 = Manure)
            if(user.versionID===version.lite){
                setShowStockFeilds(false)
            }
            else{
                if(editMode && (data.materialTypeID === 6 || data.materialTypeID === 8)){
                    setShowStockFeilds(false)
                }else{
                    setShowStockFeilds(true)
                }
            }
        }
    };


    useEffect(resetBeforeEdit, [showDialog]);

    const handleMaterialTypeChange = (event) => {
        console.debug(event)
        let materialTypeId = materialTypes[event.target.value].id
        //MaterialType ID (6 = Eggs) (8 = Manure)
        if(materialTypeId === 6 || materialTypeId === 8){
            setShowStockFeilds(false)
        }else{
            setShowStockFeilds(true)
        }
    }

    const clearInfoMessage =()=>{
        setInfoMessage("")
    }
    const onSubmit = () => {
        setPopupLoading(true)
        console.debug(formState)
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        const tempData = {
            ...formState.values,
            materialTypeID:materialTypes[formState.values.materialTypeIndex].id,
            materialTypeName: materialTypes[formState.values.materialTypeIndex].materialType,
            unitID: materialUnitTypes[formState.values.unit].id,
            unit:materialUnitTypes[formState.values.unit].name,

        };
        if (editMode)
            onEdit(tempData, setPopupLoading);
        else
            onSave({
                ...tempData,
                currentStock:formState.values.openingStock
            }, setPopupLoading);
    };

    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >{editMode?'Update':'New'} Material
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/ >
                </div>
                <br/><br/>
          <TextField disabled
                className={classes.textField}
                error={hasError('materialCode')}
                fullWidth
                helperText={
                    hasError('materialCode') ? formState.errors.materialCode[0] : null
                }
                label="Material Code"
                name="materialCode"
                onChange={handleChange}
                type="text"
                value={formState.values.materialCode || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField disabled
                className={classes.textField}
                error={hasError('materialName')}
                fullWidth
                helperText={
                    hasError('materialName') ? formState.errors.materialName[0] : null
                }
                label="Material Name"
                name="materialName"
                onChange={handleChange}
                type="text"
                value={formState.values.materialName || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <FormControl disabled className={classes.formControl}>
                <InputLabel id="materialType-select-label">Material Type</InputLabel>
                <Select
                    id="materialType-select"
                    value={formState.values.materialTypeIndex !== undefined ? formState.values.materialTypeIndex : ''}
                    onChange={event =>{handleChange(event,handleMaterialTypeChange)}}
                    name="materialTypeIndex"
                >

                    {materialTypes.map((materialType, index) =>
                        <MenuItem key={materialType.id} value={index}>{materialType.materialType}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl disabled  className={classes.formControl}>
                <InputLabel id="inventoryType-select-outlined-label">
                Inventory Type
                </InputLabel>
                <Select
                id="inventoryType-select"
                value={formState.values.inventoryType !== undefined ?formState.values.inventoryType : ''}
                onChange={event =>{
                    handleChange(event,event.target.value===2?setInventoryFarm(true):setInventoryFarm(false))}
                }
                name="inventoryType"
                >
                    <MenuItem key = {0} value={1}>Feed Mill</MenuItem>
                    <MenuItem key = {1} value={2}>Farm</MenuItem>
                </Select>
            </FormControl>
            {showStockFeilds ? 
            <span>
                {
                InventoryFarm===true
                ?
                ""
                : <span>
            <TextField disabled
                className={classes.textField}
                error={hasError('openingStock')}
                fullWidth
                helperText={
                    hasError('openingStock') ? formState.errors.openingStock[0] : null
                }
                label="Opening Stock"
                name="openingStock"
                onChange={handleChange}
                type="number"
                value={formState.values.openingStock !== undefined ? InventoryFarm===true ? 0 : formState.values.openingStock   : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField disabled
                className={classes.textField}
                error={hasError('openingValue')}
                fullWidth
                helperText={
                    hasError('openingValue') ? formState.errors.openingValue[0] : null
                }
                label="Opening Value"
                name="openingValue"
                onChange={handleChange}
                type="number"
                value={formState.values.openingValue !== undefined ? InventoryFarm===true ? 0 : formState.values.openingValue : ''  }
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            /></span>}
            <TextField disabled
                className={classes.textField}
                error={hasError('storageLoss')}
                fullWidth
                helperText={
                    hasError('storageLoss') ? formState.errors.storageLoss[0] : null
                }
                label="Storage Loss %"
                name="storageLoss"
                onChange={handleChange}
                type="number"
                value={formState.values.storageLoss !== undefined ? formState.values.storageLoss : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField disabled
                className={classes.textField}
                error={hasError('minimumStockValue')}
                fullWidth
                helperText={
                    hasError('minimumStockValue') ? formState.errors.minimumStockValue[0] : null
                }
                label="Minimum stock value"
                name="minimumStockValue"
                onChange={handleChange}
                type="number"
                value={formState.values.minimumStockValue !== undefined ? formState.values.minimumStockValue : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            </span>:null}
            <FormControl disabled className={classes.formControl}>
                <InputLabel id="inventoryType-select-outlined-label">
                    Unit Type
                </InputLabel>
                <Select disabled
                    id="unit"
                    value={formState.values.unit !== undefined ? formState.values.unit : ''}
                    onChange={event => {
                        handleChange(event)
                    }}
                    name="unit"
                >
                     {materialUnitTypes !== undefined && materialUnitTypes !== null ?
                    materialUnitTypes.map((materialUnit,index)=>
                    <MenuItem key = {materialUnit.id} value={index}>{materialUnit.name}</MenuItem>
                ):""}   
                </Select>
            </FormControl>
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}>
                    Save
                </Button>
            </DialogActions>
            </div>
            :
            <div>

          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <div id="error">
          {infoMessage ? <Chip onDelete={clearInfoMessage} color="primary" label={infoMessage}/> : ''}
        </div> 
        <br/>
        <TextField   
                 disabled={formState.values.isDefault ===1 ? true :false}
                className={classes.textField}
                error={hasError('materialCode')}
                fullWidth
                helperText={
                    hasError('materialCode') ? formState.errors.materialCode[0] : null
                }
                label="Material Code"
                name="materialCode"
                onChange={handleChange}
                type="text"
                value={formState.values.materialCode || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
             disabled={formState.values.isDefault ===1 ? true :false}
                className={classes.textField}
                error={hasError('materialName')}
                fullWidth
                helperText={
                    hasError('materialName') ? formState.errors.materialName[0] : null
                }
                label="Material Name"
                name="materialName"
                onChange={handleChange}
                type="text"
                value={formState.values.materialName || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <FormControl className={classes.formControl}    disabled={formState.values.isDefault ===1 ? true :false}>
          
                <InputLabel id="materialType-select-label">Material Type</InputLabel>
                <Select
                    id="materialType-select"
                    value={formState.values.materialTypeIndex !== undefined ? formState.values.materialTypeIndex : ''}
                    onChange={event =>{handleChange(event,handleMaterialTypeChange)}}
                    name="materialTypeIndex"
                >
                    {materialTypes.map((materialType, index) =>
                        <MenuItem key={materialType.id} value={index}>{materialType.materialType}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl  className={classes.formControl}    disabled={formState.values.isDefault ===1 ? true :false}>
                <InputLabel id="inventoryType-select-outlined-label">
                    Inventory Type
                </InputLabel>
                    <Select
                    id="inventoryType-select"
                    value={formState.values.inventoryType !== undefined ?formState.values.inventoryType : ''}
                    onChange={event =>{
                        handleChange(event,event.target.value===2?setInventoryFarm(true):setInventoryFarm(false))}
                    }
                    name="inventoryType"
                    >
                        {user.versionID===version.lite || user.versionID===version.pro?
                        <MenuItem key = {1} value={2}>Farm</MenuItem>
                        :
                        [ 
                            <MenuItem key = {0} value={1}>Feed Mill</MenuItem>,
                            <MenuItem key = {1} value={2}>Farm</MenuItem>
                        ]
                    }
                    </Select>
            </FormControl>
            {showStockFeilds ? 
            <span>
                {
                InventoryFarm===true
                ?
                ""
                : <span>
            <TextField
                className={classes.textField}
                error={hasError('openingStock')}
                disabled={formState.values.isDefault ===1 ? true :false}
                fullWidth
                helperText={
                    hasError('openingStock') ? formState.errors.openingStock[0] : null
                }
                label="Opening Stock"
                name="openingStock"
                onChange={handleChange}
                type="number"
                value={formState.values.openingStock !== undefined ? InventoryFarm===true ? 0 : formState.values.openingStock   : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('openingValue')}
                disabled={formState.values.isDefault ===1 ? true :false}
                fullWidth
                helperText={
                    hasError('openingValue') ? formState.errors.openingValue[0] : null
                }
                label="Opening Value"
                name="openingValue"
                onChange={handleChange}
                type="number"
                value={formState.values.openingValue !== undefined ? InventoryFarm===true ? 0 : formState.values.openingValue : ''  }
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            /></span>}
            
           
            <TextField
                className={classes.textField}
                error={hasError('storageLoss')}
                disabled={formState.values.isDefault ===1 ? true :false}
                fullWidth
                helperText={
                    hasError('storageLoss') ? formState.errors.storageLoss[0] : null
                }
                label="Storage Loss %"
                name="storageLoss"
                onChange={handleChange}
                type="number"
                value={formState.values.storageLoss !== undefined ? formState.values.storageLoss : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('minimumStockValue')}
                disabled={formState.values.isDefault ===1 ? true :false}
                fullWidth
                helperText={
                    hasError('minimumStockValue') ? formState.errors.minimumStockValue[0] : null
                }
                label="Minimum stock value"
                name="minimumStockValue"
                onChange={handleChange}
                type="number"
                value={formState.values.minimumStockValue !== undefined ? formState.values.minimumStockValue : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            </span>:null}
            <FormControl className={classes.formControl} 
             disabled={formState.values.isDefault ===1 ? true :false}  >
                <InputLabel id="inventoryType-select-outlined-label">
                    Unit Type
                </InputLabel>
                <Select
                    id="unit"
                    value={formState.values.unit !== undefined ? Number(formState.values.unit) : ''}
                    onChange={event => {
                      
                        handleChange(event)
                    }}
                    name="unit"
                >
  
                            {(
                                  materialUnitTypes.map((materialUnit, index) => {
                                    if (formState.values.isDefault === 1 && (materialUnit.id === 0||materialUnit.id === 2||materialUnit.id === 4)) {
                                        return null;

                                    }
                                    return(
                                    <MenuItem key={materialUnit.id} value={index}>
                                      {materialUnit.name}
                                    </MenuItem>

                                    )
                                    })
                                )}
                     
                </Select>
            </FormControl>
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}>
                    Save
                </Button>
            </DialogActions>
            </div>
          }
        </DialogContent>
    </Dialog>
};

export default MaterialMaster;