import React, {useState, useEffect} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DialogContent from '@material-ui/core/DialogContent';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { red, green } from '@material-ui/core/colors';
import { MaterialTable } from '../../../components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import useFromValidator from '../../../common/formValidator';
import {useGlobalState} from '../../../state';
import { version } from '../../../common/enums';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from  '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import api from '../../../common/api';
import {conditionOptions} from "../../../common/enums"
const schema = {
    ageCondition: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    ageConditionValue: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    ageProdCondition: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    ageProdConditionValue: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    prodFirstCondition: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    prodFirstConditionValue: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    prodSecondCondition: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    prodSecondConditionValue: {
        presence: {allowEmpty: false, message: 'is required'}
    },
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0)
    },
    spacing: {
        paddingBottom: theme.spacing(3),
        "& .MuiTableCell-root" :{
            padding:'5px'
        }
    },
    formControlLabelClass: {
        marginLeft: theme.spacing(2),
    },

}));
const commonStyles = {
    fontSize: '14px', // Match font size with the label
    height: '38px',   // Smaller height
    lineHeight: '1.5', // Ensures proper text alignment
  };

const FarmCardDetails = ({showDialog, farm, handleClose, currentFarm,onDashBoardChangeRequest,loading}) => {
    const classes = useStyles();
    const [{user}] = useGlobalState();
    const [isUsedSheds, setIsUsedSheds] = useState(false)
    const [myFarm, setMyFarm] = useState([])
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
    const [showDynamicDashBoard, setShowDynamicDashBoard] = useState(false)
    
    var date;
    var columns;
    columns =  [
        { title: 'id', field: 'id', hidden: true },
        {
            title: 'Shed Name', field: 'shedName', editable: 'never',
            render: rowData => {
                return <span style={rowData.shedName === "Total" ? { fontWeight: 'bold' } : { fontWeight: 'unset' }}>
                    {rowData.shedName}
                </span>
            }
        },
        {
            title: 'Batch Name', field: 'batchName', editable: 'never',
            render: rowData => {
                return <span>
                    {rowData.batchName}
                </span>
            }
        },
        {
            title: 'Age', field: 'age', editable: 'never',
            render: rowData => {
                return <span >
                    {rowData.shedName === "Total" ? "" : rowData.age}
                </span>
            }
        },
        {
            title: 'Feed In', field: 'feedIn', editable: 'never',
            render: rowData => {
                return <span style={rowData.shedName === "Total" ? { fontWeight: 'bold' } : { fontWeight: 'unset' }}>
                    {(rowData.feedIn).toFixed(1)}
                </span>
            }
        },
        {
            title: 'Feed Per Bird (in grams)', field: 'feedIn', editable: 'never',
            render: rowData => {
                return <span style={rowData.shedName === "Total" ? { fontWeight: 'bold' } : { fontWeight: 'unset' }}>
                    {(rowData.feedPerBird).toFixed(1)}
                </span>
            }
        },
        {
            title: 'Closing Birds', field: 'closingBirds', editable: 'never',
            render: rowData => {
                return <span style={rowData.shedName === "Total" ? { fontWeight: 'bold' } : { fontWeight: 'unset' }}>
                    {rowData.closingBirds}
                </span>
            }
        },
        {
            title: 'Egg Production', field: 'eggs', editable: 'never',
            render: rowData => {
                return <span style={rowData.shedName === "Total" ? { fontWeight: 'bold' } : { fontWeight: 'unset' }}>
                    {rowData.eggs}
                </span>
            }
        },
        {
            title: 'Production %', field: 'productionPercentage', editable: 'never',
            render: rowData => {
                return <span style={rowData.shedName === "Total" ? { fontWeight: 'bold' } : { fontWeight: 'unset' }}>
                    {rowData.productionPercentage !== "" ? (rowData.productionPercentage).toFixed(2) : ""}
                </span>
            }
        },
        {
            title: 'Used In Calculation', field: 'usedShed', editable: 'never',
            render: rowData => {
                return <span>
                    {rowData.shedName !== "Total" ? 
                     rowData.isShedDataUsed ? 
                        <CheckIcon style={{ color: green[600] }} />
                        : <ClearIcon style={{ color: red[600] }} />
                    : ""
                    }
                </span>
            }
        },
    ];

    if(user.versionID !== version.lite){
        columns.splice(8,0,{
            title: 'Egg Sales', field: 'eggSales', editable: 'never',
            render: rowData => {
                return  <span style={rowData.shedName === "Total" ? { fontWeight: 'bold' } : { fontWeight: 'unset' }}>
                    {rowData.eggSales}
                </span>
            }
        });
    }

    const setInitialValue = () => {
        if(showDialog){
            setMyFarm(farm[currentFarm.id].usedShedData);
            setIsUsedSheds(true);
        }
    }

    const onExcludeConditionSave = () => {
        const tempData = {
            ageCondition : formState.values.ageCondition !== undefined  ? formState.values.ageCondition : 3,
            ageConditionValue : formState.values.ageConditionValue || 25,
            ageProdCondition :formState.values.ageProdCondition  !== undefined  ? formState.values.ageProdCondition :  3,
            ageProdConditionValue  :formState.values.ageProdConditionValue || 75,
            prodFirstCondition :formState.values.prodFirstCondition  !== undefined  ? formState.values.prodFirstCondition :  1,
            prodFirstConditionValue  :formState.values.prodFirstConditionValue || 0,
            prodSecondCondition :formState.values.prodSecondCondition  !== undefined  ? formState.values.prodSecondCondition : 4,
            prodSecondConditionValue :formState.values.prodSecondConditionValue || 100,
            farmId:currentFarm.id
        };
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        onDashboardChange(tempData);
    };
    if (showDialog) {
        date = farm[currentFarm.id].date
    }

    useEffect(setInitialValue, [showDialog,farm]);


    const handleSwitchChange = (event) => {
        setIsUsedSheds(event.target.checked)

        if (event.target.checked === true) {
            setMyFarm(farm[currentFarm.id].usedShedData)
        } else {
            setMyFarm(farm[currentFarm.id].allShedData);
        }
    }
    const onDynamicDashboardOpen = () =>{
        onGetFarmDashoardOption()
    }
    const onGetFarmDashoardOption = () => {
        const response = api.get(`/getDynamicDashboardFarms/${currentFarm.id}`);
        response.then(res => {
            if (res) {
                setShowDynamicDashBoard(true)
                updateData(res,true)
            }
        }).catch(err => {
            console.debug("Dynamic DashBoard",err)
        }).finally((err)=>{
            setShowDynamicDashBoard(true)
        })
    };

    const onDashboardChange = (tempData) => {
        const response = api.post(`/dynamicDashboardFarms`,tempData);
        response.then(res => {
            if (res) {
                setShowDynamicDashBoard(false)
                onDashBoardChangeRequest(currentFarm)
            }
        }).catch(err => {
            console.debug("Dynamic DashBoard",err)
        }).finally(() => {
            setShowDynamicDashBoard(false)
        });
    };

    return <Dialog maxWidth="xl" disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title" >
            <DialogTitle>
                Record Date: {date !== undefined ? date : ""} 
                <FormControlLabel className={classes.formControlLabelClass} 
                control={<Switch checked={isUsedSheds} name="isUsedSheds" 
                color="primary" onChange={handleSwitchChange} />} 
                label="Hide sheds not used in calculations"
                /> 
                <IconButton onClick={onDynamicDashboardOpen}>
                    <EditIcon />
                </IconButton>
                
            </DialogTitle>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
            
            <Dialog disableBackdropClick open={showDynamicDashBoard} onClose={()=>  setShowDynamicDashBoard(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog- title"   
            >Dashboard Exclude Option</DialogTitle>
            <IconButton aria-label="close" className={classes.closeButton} onClick={()=>  setShowDynamicDashBoard(false)}>
            <CloseIcon />
            </IconButton>
                <DialogContent>

                    {/* <div style={{display: 'flex',margin:"5px 0px 5px 5px",fontSize:"15px",fontWeight:"500"}}>
                        Exclude option
                    </div> */}

                    <div style={{ display: 'flex', alignItems: 'center',marginBottom:"20px" }}>
                        {/* Age Label */}
                        <Typography
                            variant="body2" // Smaller font size (body2 matches approximately 14px)
                            style={{ marginRight: '8px', fontSize: '14px' }}
                        >
                            Age:
                        </Typography>

                        {/* Age Condition Select */}
                        <Select
                            id="ageCondition"
                            value={formState.values.ageCondition !== undefined ? formState.values.ageCondition : 3}
                            onChange={handleChange}
                            name="ageCondition"
                            variant="outlined"
                            style={{
                            ...commonStyles,
                            marginRight: '8px',
                            marginLeft:"16px",
                            width: '80px', // Smaller width for select
                            backgroundColor: '#ffffff',
                            }}
                            MenuProps={{ PaperProps: { style: { fontSize: '14px' } } }} // Ensure dropdown font matches
                        >
                            {conditionOptions?.length
                            ? conditionOptions.map((option, index) => (
                                <MenuItem key={index} value={option.key}>
                                    {option.label}
                                </MenuItem>
                                ))
                            : <MenuItem value="" disabled>No Options</MenuItem>}
                        </Select>

                        {/* Age Value TextField */}
                        <TextField
                            className={classes.textField}
                            error={hasError('ageConditionValue')}
                            helperText={hasError('ageConditionValue') ? formState.errors.ageConditionValue[0] : null}
                            name="ageConditionValue"
                            onChange={handleChange}
                            type="number"
                            value={formState.values.ageConditionValue || 25}
                            variant="outlined"
                            inputProps={{
                                style: {
                                fontSize: '14px', // Match font size
                                height: '32px',   // Set input height
                                padding: '4px 8px', // Adjust padding for alignment
                                },
                            }}
                            style={{
                                marginRight: '8px',
                                width: '80px', // Compact width
                                backgroundColor: '#ffffff',
                            }}
                            />

                        <Typography
                            variant="body2" // Smaller font size (body2 matches approximately 14px)
                            style={{ marginRight: '8px', fontSize: '14px' }}
                        >
                            And Prod %:
                        </Typography>
                        {/* Age Condition Select */}
                        <Select
                            id="ageProdCondition"
                            value={formState.values.ageProdCondition !== undefined ? formState.values.ageProdCondition : 3}
                            onChange={handleChange}
                            name="ageProdCondition"
                            variant="outlined"
                            style={{
                            ...commonStyles,
                            marginRight: '8px',
                            width: '80px', // Smaller width for select
                            backgroundColor: '#ffffff',
                            }}
                            MenuProps={{ PaperProps: { style: { fontSize: '14px' } } }} // Ensure dropdown font matches
                        >
                            {conditionOptions?.length
                            ? conditionOptions.map((option, index) => (
                                <MenuItem key={index} value={option.key}>
                                    {option.label}
                                </MenuItem>
                                ))
                            : <MenuItem value="" disabled>No Options</MenuItem>}
                        </Select>

                        {/* Age Value TextField */}
                        <TextField
                            className={classes.textField}
                            error={hasError('ageProdConditionValue')}
                            helperText={hasError('ageProdConditionValue') ? formState.errors.ageProdConditionValue[0] : null}
                            name="ageProdConditionValue"
                            onChange={handleChange}
                            type="number"
                            value={formState.values.ageProdConditionValue || 75}
                            variant="outlined"
                            inputProps={{
                                style: {
                                fontSize: '14px', // Match font size
                                height: '32px',   // Set input height
                                padding: '4px 8px', // Adjust padding for alignment
                                },
                            }}
                            style={{
                                marginRight: '8px',
                                width: '80px', // Compact width
                                backgroundColor: '#ffffff',
                            }}
                            />

                    </div>
                    <div style={{ display: 'flex', alignItems: 'center',marginBottom:"20px" }}>
                        {/* Age Label */}
                        <Typography
                            variant="body2" // Smaller font size (body2 matches approximately 14px)
                            style={{ marginRight: '8px', fontSize: '14px' }}
                        >
                            Prod%:
                        </Typography>

                        {/* Prod Condition Select */}
                        <Select
                            id="prodFirstCondition"
                            value={formState.values.prodFirstCondition !== undefined ? formState.values.prodFirstCondition : 1}
                            onChange={handleChange}
                            name="prodFirstCondition"
                            variant="outlined"
                            style={{
                            ...commonStyles,
                            marginRight: '8px',
                            width: '80px', // Smaller width for select
                            backgroundColor: '#ffffff',
                            }}
                            MenuProps={{ PaperProps: { style: { fontSize: '14px' } } }} // Ensure dropdown font matches
                        >
                            {conditionOptions?.length
                            ? conditionOptions.map((option, index) => (
                                <MenuItem key={index} value={option.key}>
                                    {option.label}
                                </MenuItem>
                                ))
                            : <MenuItem value="" disabled>No Options</MenuItem>}
                        </Select>

                        {/* Age Value TextField */}
                        <TextField
                            className={classes.textField}
                            error={hasError('prodFirstConditionValue')}
                            helperText={hasError('prodFirstConditionValue') ? formState.errors.prodFirstConditionValue[0] : null}
                            name="prodFirstConditionValue"
                            onChange={handleChange}
                            type="number"
                            value={formState.values.prodFirstConditionValue || 0}
                            variant="outlined"
                            inputProps={{
                                style: {
                                fontSize: '14px', // Match font size
                                height: '32px',   // Set input height
                                padding: '4px 8px', // Adjust padding for alignment
                                },
                            }}
                            style={{
                                marginRight: '8px',
                                width: '80px', // Compact width
                                backgroundColor: '#ffffff',
                            }}
                            />

                        <Typography
                            variant="body2" // Smaller font size (body2 matches approximately 14px)
                            style={{ marginRight: '8px', fontSize: '14px' }}
                        >
                            And 
                        </Typography>
                        {/* Age Condition Select */}
                        <Select
                            id="prodSecondCondition"
                            value={formState.values.prodSecondCondition !== undefined ? formState.values.prodSecondCondition : 4}
                            onChange={handleChange}
                            name="prodSecondCondition"
                            variant="outlined"
                            style={{
                            ...commonStyles,
                            marginRight: '8px',
                            width: '80px', // Smaller width for select
                            backgroundColor: '#ffffff',
                            }}
                            MenuProps={{ PaperProps: { style: { fontSize: '14px' } } }} // Ensure dropdown font matches
                        >
                            {conditionOptions?.length
                            ? conditionOptions.map((option, index) => (
                                <MenuItem key={index} value={option.key}>
                                    {option.label}
                                </MenuItem>
                                ))
                            : <MenuItem value="" disabled>No Options</MenuItem>}
                        </Select>

                        {/* Age Value TextField */}
                        <TextField
                            className={classes.textField}
                            error={hasError('prodSecondConditionValue')}
                            helperText={hasError('prodSecondConditionValue') ? formState.errors.prodSecondConditionValue[0] : null}
                            name="prodSecondConditionValue"
                            onChange={handleChange}
                            type="number"
                            value={formState.values.prodSecondConditionValue || 100}
                            variant="outlined"
                            inputProps={{
                                style: {
                                fontSize: '14px', // Match font size
                                height: '32px',   // Set input height
                                padding: '4px 8px', // Adjust padding for alignment
                                },
                            }}
                            style={{
                                marginRight: '8px',
                                width: '80px', // Compact width
                                backgroundColor: '#ffffff',
                            }}
                            />

                    </div>

                    <Button onClick={onExcludeConditionSave} 
                    className={classes.signInButton}
                    color="primary"
                    //  disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    >
                        Save
                    </Button>
                </DialogContent>
            </Dialog>
            <div id='report-container' className={classes.spacing} >
                <MaterialTable
                    columns={columns}
                    isLoading={loading}
                    data={myFarm !== undefined ? myFarm : []}
                    title='Shed Data Table'
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false

                    }}
                />
            </div>

            </DialogContent>
            
        </Dialog>
}

export default FarmCardDetails